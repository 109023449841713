<template>
  <div class="wallet">
    <Head recruit="true"></Head>
    <div class="main">
      <div class="content">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/recruit' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/recruitCenter' }">我的</el-breadcrumb-item>
        <el-breadcrumb-item>余额明细</el-breadcrumb-item>
      </el-breadcrumb>
        <div class="title">账户明细</div>
        <ul class="walletList" v-if="mxList.length">
          <li v-for="item in mxList" :key="item.id">
            <div class="money">-{{item.price}}</div>
            <img v-if="item.payStyle === 1" src="../../assets/img/recruit/img_wx.png" alt="" />
            <img v-if="item.payStyle === 2" src="../../assets/img/recruit/img_wx.png" alt="" />
            <div class="text">
              <h4>{{item.orderType===1?'推广':'刷新'}}</h4>
              <p>{{item.payTime}}</p>
            </div>
          </li>
        </ul>
        <div v-else style="text-align: center;padding-top: 50px; font-size: 14px; color: #999">暂无数据</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { wallet } from '@/api/zp'
export default {
  name: 'Wallet',
  data() {
    return {
      mxList: []
    };
  },
  created() {
    this.getWallet()
  },
  components: {
    Head,
    Footer,
  },
  methods: {
    getWallet(){
      wallet().then(res => {
        this.mxList = res.data
      })
    }
  },
};
</script>
<style scoped lang="scss">
.main {
  min-height: calc(100vh - 278px);
}
.el-breadcrumb {
  margin: 26px 0;
}
.address {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin: 20px 0;
  span{
    cursor: pointer;
  }
}
.title{
  height: 98px;
  background: #FFFFFF;
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  line-height: 98px;
  padding: 0 16px;
}
.walletList{
  overflow: hidden;
  margin: 20px 0;
  background: #fff;
  padding:0 16px;
  li{
    height: 111px;
    overflow: hidden;
    border-bottom: 1px solid #EEEEEE;
    padding-top: 24px;
    box-sizing: border-box;
    &:last-child{
      border: 0;
    }
    .money{
      float: right;
      font-size: 20px;
      font-weight: bold;
      color: #FF2647;
      margin-top: 24px;
    }
    img{
      float: left;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      margin-right: 16px;
    }
    .text{
      overflow: hidden;
      padding-top: 8px;
      h4{
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>
